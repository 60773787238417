// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// services
import Marketplace from '@services/marketplace';
import LocalStorage from '@services/ts/localStorage';

// router
import { useRouter } from 'vue-router';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import { useMessage } from 'naive-ui';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const marketplace = useMarketplace();

    // router
    const router = useRouter();

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const marks = {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
    };
    const loadings = reactive({
        table: false,
    });

    // storage filters
    const storeFilterKey = '___filters_marketplace-my-mentors';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({}));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const init = ref(false);
    const mentorRate = ref(3);
    const questionInfo = ref();
    const showQuestionInfo = ref(false);
    const questionInfoAgree = ref(false);
    const questionInfoLoading = ref(false);

    const actions = computed(() => ['marketplace_mentors__question_info']);
    const buttonDisabled = computed(() => !questionInfoAgree.value);
  
    const requestQuestionInfo = async () => {
        if (!questionInfoAgree.value) return;
        if (!questionInfo.value.id || !mentorRate.value) return;

        questionInfoLoading.value = true;
        await Marketplace.mentorshipDoneFromMentee({
            id: questionInfo.value.id,
            mentor_rate: mentorRate.value,
        });
        questionInfoLoading.value = false;

        showQuestionInfo.value = false;

        getData();
    };

    const getData = async () => {
        loadings.table = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);

        try {
            const prepare = await Marketplace.myMentorsGet({
                pager: filters,
            });

            if (marketplace.myMentorsNotEmpty) {
                Object.keys(prepare.data).forEach(key => {
                    if (!['columns'].includes(key)) {
                        marketplace.myMentors[key] = prepare.data[key];
                    }
                });
            } else {
                marketplace.myMentors = prepare.data;
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loadings.table = false;
    };

    const sortColumn = $event => {
        _.set(
            filters,
            'sort_column',
            $event.columnKey !== undefined && $event.order !== undefined
                ? $event.columnKey
                : '');

        _.set(
            filters,
            'sort_dir',
            $event.order !== undefined
                ? $event.order
                : '');

        getData();
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            onQuestionInfo: () => {
                showQuestionInfo.value = true;
                questionInfo.value = marketplace.myMentorsRecords.find(({ id }) => id === value);
            },
        };

        fns[action]();
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && marketplace.myMentors.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_marketplace-my-mentors', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
      
        if (update || canUpdate)
            getData();
    };

    onMounted( async () => {
        await getData();

        init.value = true;
    });

    return {
        gl,
        refs,
        init,
        marks,
        filters,
        actions,
        loadings,
        mentorRate,
        marketplace,
        questionInfo,
        buttonDisabled,
        showQuestionInfo,
        questionInfoAgree,
        questionInfoLoading,
        getData,
        sortColumn,
        doSomething,
        changeFilter,
        requestQuestionInfo,
    };
}